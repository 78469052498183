import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import IntroView from "@/components/IntroView.vue";
import VideoView from "@/components/VideoView.vue";
import QuestionView from "@/components/QuestionView.vue";
import CompletedView from "@/components/CompletedView.vue";
import SummaryOne from "@/components/SummaryOne.vue";
import SummaryTwo from "@/components/SummaryTwo.vue";
import SummaryFour from "@/components/SummaryFour.vue";
import SummaryThree from "@/components/SummaryThree.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'IntroView',
    component: IntroView
  },
  {
    path: '/video/:index',
    name: 'VideoView',
    component: VideoView,
    props: true
  },
  {
    path: '/questions/:questionId',
    name: 'QuestionView',
    component: QuestionView,
    props: true,
  },
  {
    path: '/summary/1',
    name: 'SummaryOne',
    component: SummaryOne,
  },
  {
    path: '/summary/2',
    name: 'SummaryTwo',
    component: SummaryTwo,
  },
  {
    path: '/summary/3',
    name: 'SummaryThree',
    component: SummaryThree,
  },
  {
    path: '/summary/4',
    name: 'SummaryFour',
    component: SummaryFour,
  },
  {
    path: '/completed',
    name: 'CompletedView',
    component: CompletedView,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

export default router


/*
<div>
    <div v-if="part === 0">
      <IntroView />
    </div>
    <div v-if="part === 1">
      <QuestionsView :part="0"/>
    </div>
    <div v-if="part === 2">
      <VideoView />
    </div>
    <div v-if="part === 3">
      <QuestionsView :part="1" />
    </div>
    <div v-if="part === 4">
      <CompletedView />
    </div>
  </div>
 */
