import {Answer, SubmitAnswersRequest} from "@/data/interfaces";
import axios, {AxiosResponse} from "axios";

const API_PATH = "https://mct-api.babel.as"


/************************************************************************************
 *                               API Calls
 ***********************************************************************************/

export interface SubmitAnswersResponse {
    success: boolean
}
export const sendAnswers = async (answers: Answer[]): Promise<boolean> => {
    const data: SubmitAnswersRequest = {
        answers: answers
    }
    const result = await executeAPIPost<SubmitAnswersResponse>('/survey', data)
    return !!result.data?.success;

}

/************************************************************************************
 *                               Helpers
 ***********************************************************************************/

const getHeaders = () => {
    const headers: {[index: string]: string} = {
        'Content-Type': 'application/json',
    }
    return headers
}

export interface APIResult<T> {
    data?: T,
    error?: Error
}

export async function executeAPIPost<T>(path: string, data: any, headers: any = getHeaders()): Promise<APIResult<T>> {
    return axios.post(API_PATH + path, data, { headers: headers })
        .then(function (response: AxiosResponse<any>) {
            const data = response.data as T
            return {
                data: data,
            }
        }).catch(function (error) {
            return { error: error }
        });
}

export async function executeAPIGet<T>(path: string, headers: any = getHeaders()): Promise<APIResult<T>> {

    return axios.get(API_PATH + path, { headers: headers })
        .then(function (response: AxiosResponse<any>) {
            const data = response.data as T
            return {
                data: data,
            }
        }).catch(function (error) {
            return { error: error }
        });
}
