import {ref} from "vue";
import {Answer, Question} from "@/data/interfaces";
import {sendAnswers} from '@/data/api-functions'
import * as localStore from "local-storage"
import router from "@/router/router";

export enum Part {
	INTRO,
	QUESTIONS_ONE,
	VIDEO_ONE,
	SUMMARY_ONE,
	VIDEO_TWO,
	SUMMARY_TWO,
	VIDEO_THREE,
	SUMMARY_THREE,
	VIDEO_FOUR,
	SUMMARY_FOUR,
	QUESTIONS_TWO,
	SUBMITTED
}

type AnswerMap = Map<string, number>

export const questionAnswers = ref<AnswerMap>(new Map<string, number>())



/*
export const setPart = (value: Part, index?: number) => {
	const videoNr = index || 0
	switch (value) {
		case Part.INTRO:
			router.push('/')
			break;
		case Part.QUESTIONS_ONE:
			router.push(`/questions/${index || 0}`)
			break;
		case Part.VIDEO_ONE: case Part.VIDEO_TWO:
			router.push(`/video/${index || 0}`)
			// router.push({name: 'VideoView', params: { index: videoNr }})
			break;
		case Part.QUESTIONS_TWO:
			router.push(`/questions/${index || 9}`)
			break;
		case Part.SUBMITTED:
			router.push('/completed')
			break;

	}
}
 */

export const nextPage = async (part: number, index?: number) => {
	console.log('Next: ' + part)
	const videoNr = index || 0
	switch (part) {
		case Part.INTRO:
			await router.push(`/questions/1`)
			console.log('Go to questions')
			break;
		case Part.QUESTIONS_ONE:
			storeAnswers()
			await router.push('/video/0')
			break;
		case Part.VIDEO_ONE:
			await router.push('/summary/1')
			break
		case Part.SUMMARY_ONE:
			await router.push('/video/1')
			break
		case Part.VIDEO_TWO:
			await router.push('/summary/2')
			break
		case Part.SUMMARY_TWO:
			await router.push('/video/2')
			break
		case Part.VIDEO_THREE:
			await router.push('/summary/3')
			break
		case Part.SUMMARY_THREE:
			await router.push('/video/3')
			break
		case Part.VIDEO_FOUR:
			await router.push('/summary/4')
			break
		case Part.SUMMARY_FOUR:
			router.push(`/questions/9`)
			break;
		case Part.QUESTIONS_TWO:
			if (await submitAnswers()) {
				router.push('/completed')
				resetLocalStore()
			} else {
				console.error('Failed to store.')
			}

			break;
	}
}


export const previousPage = async (part: number, index?: number) => {
	const videoNr = index || 0
	switch (part) {
		case Part.INTRO:
			break;
		case Part.QUESTIONS_ONE:
			// setPart(Part.INTRO)
			break;
		case Part.VIDEO_ONE:
			await router.push('/questions/8')
			break
		case Part.SUMMARY_ONE:
			await router.push('/video/0')
			break
		case Part.VIDEO_TWO:
			await router.push('/summary/1')
			break
		case Part.SUMMARY_TWO:
			await router.push('/video/1')
			break
		case Part.VIDEO_THREE:
			await router.push('/summary/2')
			break
		case Part.SUMMARY_THREE:
			await router.push('/video/2')
			break
		case Part.VIDEO_FOUR:
			await router.push('/summary/3')
			break
		case Part.SUMMARY_FOUR:
			await router.push('/video/3')
			break
		case Part.QUESTIONS_TWO:
			await router.push('/summary/4')
			// setPart(Part.VIDEO, 3)
			break;
		case Part.SUBMITTED:
			break;
	}
}

/*
export const nextButtonText = computed(() => {
	if (part.value === Part.SUBMITTED) return undefined
	return "Neste"
})

export const previousButtonText = computed(() => {
	if (part.value === Part.VIDEO) {
		return "Tilbake"
		// return (currentVideoIndex.value > 0) ? "Tilbake"
	}
	return undefined
})
*/


export const loadLocalStore = () => {
	loadAnswers()
}

export const submitAnswers = async (): Promise<boolean> => {
	const answers: Answer[] = []
	questionAnswers.value.forEach((value, key) => {
		if (value < 0) return
		answers.push({
			questionId: key,
			value: value
		})
	})
	return await sendAnswers(answers)
}

export const questions: Question[] = [
	{
		questionText: "Føler du at du bruker mye tid på å tenke på ting som kan komme til å skje i fremtiden?",
		questionId: '1',
		options: [
			"Nei 👎",
			"En del 🤏",
			"Ofte 👍",
			"Vet ikke 🫳"]
	},
	{
		questionText: "Føler du at du bruker mye tid på å tenke på ting eller situasjoner som har skjedd i fortiden?",
		questionId: '2',
		options: [
			"Nei 👎",
			"En del 🤏",
			"Ofte 👍",
			"Vet ikke 🫳"]
	},
	{
		questionText: "Opplever du noen ganger at tankene spinner ut av kontroll og at du kverner om og om igjen på de samme tankene?",
		questionId: '3',
		options: [
			"Nei 👎",
			"En del 🤏",
			"Ofte 👍",
			"Vet ikke 🫳"]
	},
	{
		questionText: "Føler du et behov for å kontrollere tankene dine?",
		questionId: '4',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳",]
	},
	{
		questionText: "Oppfatter du at det er du som skaper tankene dine, eller at de dukker opp mer eller mindre tilfeldig?",
		questionId: '5',
		options: [
			"Jeg skaper tankene mine 🪄",
			"De dukker opp tilfeldig 🎲",
			"Vet ikke 🫳",]
	},
	{
		questionText: "Tenker du at du kan velge helt selv hvilke tanker du vier oppmerksomhet, eller er det noen tanker du bare må ta stilling til?",
		questionId: '6',
		options: [
			"Jeg velger selv 🧠",
			"Har ikke noe valg 🔒",
			"Vet ikke 🫳",]
	},
	{
		questionText: "Er du som regel bevisst på hvor tankefokuset ditt er? Altså om du tenker på fortiden, fremtiden osv.",
		questionId: '7',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳"]
	},
	{
		questionText: "Bruker du som regel lang tid på å sovne?",
		questionId: '8',
		options: [
			"Nei 👎",
			"Noen ganger 🫳",
			"Ja 👍",
		]
	},
	{
		questionText: "Har du bedre forståelse for hva bekymringer er etter å ha sett filmserien?",
		questionId: '9',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳"]
	},
	{
		questionText: "Har du bedre forståelse for hva grubling er etter å ha sett filmserien?",
		questionId: '10',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳"]
	},
	{
		questionText: "Forstår du i større grad enn før hva tankekjør er?",
		questionId: '11',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳",]
	},
	{
		questionText: "Har du blitt mer bevisst på hvor du har tankefokuset ditt?",
		questionId: '12',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳",]
	},
	{
		questionText: "Føler du i mindre grad enn før at du behøver å kontrollere tankene dine?",
		questionId: '13',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳",]
	},
	{
		questionText: "Er du i større grad enn før bevisst på hvordan tanker oppstår?",
		questionId: '14',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳",]
	},
	{
		questionText: "Føler du deg bedre rustet til å kunne styre hvilke tanker du vier oppmerksomhet?",
		questionId: '15',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳",]
	},
	{
		questionText: "Føler du deg bedre rustet til å håndtere situasjoner der du har vanskeligheter med å sovne?",
		questionId: '16',
		options: [
			"Ja 👍",
			"Nei 👎",
			"Vet ikke 🫳"]
	}]

export const videoIds = [
	'gG-4XEVxn0U',
	'0ER8rzfvWZU',
	'07mvxrnrT7g',
	'0-s-scozVtU'
]

export const videoTitles = [
	'Oppmerksomhet',
	'Bekymring',
	'Grubling',
	'Søvn'
]
export const videoTexts = [
	'I denne episoden ser vi på hvordan hvor vi velger å rette oppmerksomheten vår har stor betydning for vår mentale tilstand. Vi undersøker også hva tankekjør er og hvordan grubling og bekymring kan føre til forskjellige typer psykiske vansker.',
	'Vi mennesker er veldig flinke til å se for oss ting som kan komme til å skje. Noen ganger bruker vi såpass mye tid og energi på å bekymre oss for disse tingene som kanskje kommer til å skje at det hemmer oss. Men hva er egentlig bekymringer, og hvordan kan vi unngå at de spinner ut av kontroll?',
	'Noen ganger opplever vi hendelser og situasjoner som det er veldig vanskelig å slippe taket i. Hvorfor skjer det, og hvordan kan vi komme oss ut av den mentale gyngestolen?',
	'Noe av det mest irriterende som finnes er når vi legger oss til å sove, men så lar ikke hjernen vår oss få ro. Nye tanker dukker opp i hytt og pine. Hvorfor er det sånn,  og finnes det noen enkle triks vi kan bruke for å senke tankeaktiviteten og få ro i kroppen? Vi spør Hans.'
]

/************************************************************************************
 *                               Local Persistence
 ***********************************************************************************/

export const storeAnswers = () => {
	const json = JSON.stringify(Array.from(questionAnswers.value.entries()));
	localStore.set<string>('answers', json)
	console.log(`Stored ${questionAnswers.value.size} answers.`)
}

export const loadAnswers = () => {
	const answersJSON = localStore.get<string>('answers')
	const answers = new Map<string, number>(JSON.parse(answersJSON))
	if (answers.size > 0) {
		questionAnswers.value = answers
		console.log(`Loaded ${answers.size} answers.`)
	} else {
		console.log('No answers loaded')
	}

}

export const resetLocalStore = () => {
	localStore.clear()
	console.log('Cleared local store.')
}
