<script setup lang="ts">


</script>
<template>
  <div style="background-color: #CE8179; color: white; height: 100vh;  width: 100%; display: flex; justify-content: center; overflow-x: hidden">
    <div class="introContentWrapper">
      <div class="introTextWrapper">
        <h1>Du har fullført serien! 🥳</h1>
        <p>Dersom du vet om noen andre som kan ha nytte av serien, del den gjerne!</p>

        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top:1.5em; margin-bottom: 0em;color: white;">
          <b style="font-weight: 400">Et prosjekt av <b>Hverdagshelt</b></b>
          <b style="font-weight: 400; margin-top: .5em;">I samarbeid med</b>
          <img style="height: 1.5em; margin-top: .8em; margin-bottom: .8em;" src="@/assets/psykopp_logo_white_web.svg">
          <b style="font-weight: 400">Støttet av</b>
          <img style="height: 100%; width: 4em; margin-top: .8em;" src="@/assets/stiftelsenDam.png">
        </div>
      </div>
      <div style="display: flex; flex-grow: 1; align-items: end; justify-content: center; max-width: 50%">
        <img style="max-height: 20em; margin-bottom: 0em;" src="@/assets/dude.png">
      </div>
    </div>
  </div>
</template>

<style scoped>

.introContentWrapper {
  display: flex; flex-direction: column; max-width: 70em; align-items: center;
}

.introTextWrapper {
  display: flex; flex-direction: column; flex-grow: 1; align-items: center; text-align: center; max-width: 80%; padding: 4em;
}

.getStartedInnerWrapper {
  margin: 5em; position: relative; display: flex; justify-content: center; align-items: center; max-width: 70em;
}

@media (max-width: 700px) {
  .getStartedInnerWrapper {
    margin: 2em; position: relative; display: flex; justify-content: center; align-items: center; max-width: 70em;
  }
}

</style>